import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../organisms/Header/Header";
import Footer from "../../organisms/Footer/Footer";
import { NewsProps } from "../../asset/text/news";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { createClient } from "contentful";
import "./NewsDetail.scss";

const NewsDetail = () => {
  const client = createClient({
    space: process.env.REACT_APP_SPACE_ID!,
    accessToken: process.env.REACT_APP_API_TOKEN!,
  });

  const [results, setResults] = useState<NewsProps[]>([]);

  useEffect(() => {
    const fetchEntries = async () => {
      const entries = await client.getEntries({
        content_type: "testLocale",
      });

      const newResults: NewsProps[] = entries.items.map((item, index) => ({
        id: index.toString(),
        date: item.fields.date!.toString(),
        title: item.fields.title! as string,
        tag: item.fields.tag! as string,
        detail: item.fields.body! as string,
      }));

      const sortedResults: NewsProps[] = newResults.sort((a, b) => {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        return dateB - dateA;
      });
      setResults(sortedResults);
      console.log(sortedResults);
    };
    fetchEntries();
  }, []);

  const { id } = useParams();
  const targetNews = results.find((result) => result.id === id);
  if (!targetNews) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{targetNews!.title}</title>
        <meta name="description" content={targetNews?.detail} />
      </Helmet>
      <Header currentPage={"お知らせ"} />
      <div className="wrapper">
        <div className="container-newsdetail">
          <div className="breadcrumb">
            <span>
              <Link to={"/"}>ホーム</Link>
            </span>
            <span>&nbsp;&nbsp;&gt;&nbsp;&nbsp;</span>
            <span>
              <Link to={"/news"}>お知らせ一覧</Link>
            </span>
            <span>&nbsp;&nbsp;&gt;&nbsp;&nbsp;</span>
            <span>お知らせ詳細</span>
          </div>
          <div className="whole-newsdetail">
            <div className="meta-newsdetail">
              <p className="date-newsdetail">{targetNews?.date}</p>
              <span className="tag-newsdetail">{targetNews?.tag}</span>
            </div>
            <h2 className="title-newsdetail">{targetNews?.title}</h2>
            <div className="detail-newsdetail">
              <p>
                {targetNews?.detail.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && <br />}
                    {line}
                  </React.Fragment>
                ))}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NewsDetail;
