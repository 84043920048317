import React from "react";
import "./CompanyInfo.scss";
import ContentBar from "../../molecules/ContentBar/ContentBar";
import CompanyDetail from "../../molecules/CompanyDetails/CompanyDetail";

const CompanyInfo = () => {
  return (
    <div className="container-company">
      <ContentBar title={"会社概要"}></ContentBar>
      <CompanyDetail title={"会社名"} info={"ライブラ株式会社"} />
      <CompanyDetail title={"所在地"} info={"東京都中央区晴海一丁目"} />
      <CompanyDetail title={"設立"} info={"2013年7月"} />
      <CompanyDetail title={"代表"} info={"鈴木康裕"} />
      <CompanyDetail
        title={"ミッション"}
        info={
          "人々が、楽しく持続的に、それぞれの力を生産的な活動に注げる世界を作る"
        }
      />
      <CompanyDetail
        title={"事業内容"}
        info={`・インターネットメディア事業\n・インターネットビジネスに関するコンサルティング 事業\n・ツアー&アクティビティ事業への投資事業`}
      />
    </div>
  );
};

export default CompanyInfo;
