import React from "react";
import mainvisual from "../../asset/img/main_visual.jpg";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import "./MainVisual.scss";

const MainVisual = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return isMobile ? (
    <div className="wrapper-mv">
      <Grid container className="container-mv">
        <div className="image-wrapper-mv">
          <img src={mainvisual} />
        </div>
        <Grid item xs={12} md={6} className="message">
          <div className="message-texts">
            <div className="message-title">
              <h2>
                人々が価値の創出に
                <br />
                持続的に取り組める世の中へ
              </h2>
            </div>
            <div className="message-body">
              <p>
                人々が楽しみながら自分の力を価値の創出に用いることができるどうかは、事業や仕事の設計次第です。
                <br />
                我々は技術活用を軸に、持続的に人々が価値創出に集中できる生産性の高い事業を構築する集団です。
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  ) : (
    <div className="top-bg">
      <div className="wrapper">
        <Grid container className="container-mv">
          <Grid item xs={12} md={6} className="mainvisual">
            <div className="image-wrapper-mv">
              <img src={mainvisual} />
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="message">
            <div className="message-texts">
              <div className="message-title">
                <h2>
                  人々が価値の創出に
                  <br />
                  持続的に取り組める世の中へ
                </h2>
              </div>
              <div className="message-body">
                <p>
                  人々が楽しみながら自分の力を価値の創出に用いることができるどうかは、事業や仕事の設計次第です。
                  <br />
                  我々は技術活用を軸に、持続的に人々が価値創出に集中できる生産性の高い事業を構築する集団です。
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default MainVisual;
