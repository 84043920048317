import React from "react";
import "./ContentBar.scss";

type ContentBarProps = {
  title: string;
};
const ContentBar: React.FC<ContentBarProps> = ({ title }) => {
  return (
    <div className="container-contentbar">
      <div className="bar-title">
        <p>{title}</p>
      </div>
    </div>
  );
};

export default ContentBar;
