import React, { useState } from "react";
import "./Header.scss";
import logo from "../../asset/img/logo.png";
import { Link } from "react-router-dom";
import {
  Grid,
  Menu,
  MenuItem,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const Header = ({ currentPage }: { currentPage: string }) => {
  const [activeLink, setActiveLink] = useState(currentPage);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const links = [
    { name: "ホーム", url: "/" },
    { name: "会社概要", url: "/company" },
    { name: "お知らせ", url: "/news" },
    { name: "採用情報", url: "/recruit" },
    { name: "お問い合わせ", url: "/query" },
  ];

  return (
    <div className="wrapper-header">
      <Grid container className="container-header">
        <Grid item xs={3} className="logo">
          <Link to={"/"}>
            <img src={logo} alt="libra inc" />
          </Link>
        </Grid>
        <Grid item xs={8} className="menu">
          {isMobile ? (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {links.map((link) => (
                  <MenuItem
                    key={link.name}
                    className={`link-menu ${
                      activeLink === link.name ? "active" : ""
                    }`}
                  >
                    <a href={link.url}>{link.name}</a>
                  </MenuItem>
                ))}
              </Menu>
            </>
          ) : (
            links.map((link) => (
              <Grid
                key={link.name}
                className={`link-menu ${
                  activeLink === link.name ? "active" : ""
                }`}
              >
                <a href={link.url}>{link.name}</a>
              </Grid>
            ))
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Header;
