import React, { useState, useEffect } from "react";
import "./Content.scss";
import { Grid } from "@mui/material";
import Service from "../../molecules/Service/Service";
import News from "../../molecules/News/News";
import ContentBar from "../../molecules/ContentBar/ContentBar";
import { NewsProps } from "../../asset/text/news";
import { serviceContent, ServiceProps } from "../../asset/text/service";
import { Link } from "react-router-dom";
import { createClient } from "contentful";

const Content = () => {
  const client = createClient({
    space: process.env.REACT_APP_SPACE_ID!,
    accessToken: process.env.REACT_APP_API_TOKEN!,
  });

  const [results, setResults] = useState<NewsProps[]>([]);

  useEffect(() => {
    const fetchEntries = async () => {
      const entries = await client.getEntries({
        content_type: "testLocale",
      });

      const newResults: NewsProps[] = entries.items.map((item, index) => ({
        id: index.toString(),
        date: item.fields.date!.toString(),
        title: item.fields.title! as string,
        tag: item.fields.tag! as string,
        detail: item.fields.body! as string,
      }));

      const sortedResults: NewsProps[] = newResults.sort((a, b) => {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        return dateB - dateA;
      });
      setResults(sortedResults);
    };
    fetchEntries();
  }, []);

  return (
    <Grid container className="wrapper-content">
      <Grid item xs={12} md={9} className="left-column">
        <ContentBar title={"サービス紹介"} />
        {serviceContent.map((service: ServiceProps) => (
          <Service
            title={service.title}
            message={service.message}
            image={service.image}
          />
        ))}
      </Grid>
      <Grid item xs={12} md={3} className="right-column">
        <ContentBar title={"お知らせ"} />
        {results.map((item: NewsProps) => (
          <News
            id={item.id}
            title={item.title}
            tag={item.tag}
            date={item.date}
            detail={item.detail}
          />
        ))}
        <div className="link-more">
          <Link to={`/news`}>もっと見る</Link>
        </div>
      </Grid>
    </Grid>
  );
};

export default Content;
