import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="container-footer">
      <p className="copyright">
        Copyright c 2014 Libra Co.,Ltd. All Rights Reserved.
      </p>
    </div>
  );
};

export default Footer;
