import React from "react";
import { Link } from "react-router-dom";
import Header from "../../organisms/Header/Header";
import Footer from "../../organisms/Footer/Footer";
import ContentBar from "../../molecules/ContentBar/ContentBar";
import { Helmet } from "react-helmet";
import "./Recruit.scss";

const Recruit = () => {
  return (
    <>
      <Helmet>
        <title>Recruiting</title>
        <meta
          name="description"
          content="List of positions/job categories currently open at Libra Inc"
        />
      </Helmet>
      <Header currentPage={"採用情報"} />
      <div className="wrapper">
        <div className="container-recruit">
          <div className="breadcrumb">
            <span>
              <Link to={"/"}>ホーム</Link>
            </span>
            <span>&nbsp;&nbsp;&gt;&nbsp;&nbsp;</span>
            <span>お知らせ一覧</span>
          </div>
          <ContentBar title={"採用情報"} />
          <span className="text-recruit">現在採用は行っておりません</span>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Recruit;
