import mediaBiz from "../../asset/img/media-biz.webp";
import consultingBiz from "../../asset/img/consulting-biz.png";
import investBiz from "../../asset/img/invest-biz.png";

export type ServiceProps = {
  title: string;
  message: string;
  image: string;
};

export const serviceContent: ServiceProps[] = [
  {
    title: "インターネットメディア事業",
    message:
      "創業以来、様々なインターネットメディアを企画・開発・運営しております。運営するメディアは月間数千万アクセスを誇り、多くの有用なオリジナルコンテンツを社会に提供しています。",
    image: mediaBiz,
  },
  {
    title: "コンサルティング事業",
    message:
      "弊社のグロース・マーケティングノウハウを提供し、インターネットビジネスを成長させます。現在、MAUベースで合計数千万規模となる様々なサービスに関与しており、高い戦略構築能力と業務設計能力に定評があります。",
    image: consultingBiz,
  },
  {
    title: "投資事業",
    message:
      "事業買収及びバリューアップと事業売却の経験を多数有しております。またツアー・アクティビティ領域における販売・オペレーション最適化サービス「MagicalTrip」への投資を行っております。",
    image: investBiz,
  },
];
