import React from "react";
import Header from "../../organisms/Header/Header";
import MainVisual from "../../organisms/MainVisual/MainVisual";
import Content from "../../organisms/Content/Content";
import Footer from "../../organisms/Footer/Footer";
import { Helmet } from "react-helmet";

const Top = () => {
  return (
    <>
      <Helmet>
        <title>Libra Inc.</title>
        <meta
          name="description"
          content="We are a global team utilizing technology to build businesses that enable people to approach their work with high productivity and enthusiasm."
        />
      </Helmet>
      <Header currentPage="ホーム" />
      <MainVisual />
      <Content />
      <Footer />
    </>
  );
};

export default Top;
