import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../organisms/Header/Header";
import Footer from "../../organisms/Footer/Footer";
import News from "../../molecules/News/News";
import { newsContent, NewsProps } from "../../asset/text/news";
import ContentBar from "../../molecules/ContentBar/ContentBar";
import { Helmet } from "react-helmet";
import { createClient } from "contentful";
import "./NewsList.scss";

const NewsList = () => {
  const client = createClient({
    space: process.env.REACT_APP_SPACE_ID!,
    accessToken: process.env.REACT_APP_API_TOKEN!,
  });

  const [results, setResults] = useState<NewsProps[]>([]);

  useEffect(() => {
    const fetchEntries = async () => {
      const entries = await client.getEntries({
        content_type: "testLocale",
      });

      const newResults: NewsProps[] = entries.items.map((item, index) => ({
        id: index.toString(),
        date: item.fields.date!.toString(),
        title: item.fields.title! as string,
        tag: item.fields.tag! as string,
        detail: item.fields.body! as string,
      }));

      const sortedResults: NewsProps[] = newResults.sort((a, b) => {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        return dateB - dateA;
      });
      setResults(sortedResults);
      console.log(sortedResults);
    };
    fetchEntries();
  }, []);

  return (
    <>
      <Helmet>
        <title>News</title>
      </Helmet>
      <Header currentPage={"お知らせ"} />
      <div className="wrapper">
        <div className="breadcrumb">
          <span>
            <Link to={"/"}>ホーム</Link>
          </span>
          <span>&nbsp;&nbsp;&gt;&nbsp;&nbsp;</span>
          <span>お知らせ一覧</span>
        </div>
        <div className="container-newslist">
          <ContentBar title={"お知らせ"} />
          {results.map((item, index) => (
            <div className="detail-news">
              <News
                id={item.id}
                title={item.title}
                tag={item.tag}
                date={item.date}
                detail={item.detail}
              />
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NewsList;
