import React from "react";
import ReactDOM from "react-dom/client";
import Header from "../../organisms/Header/Header";
import Footer from "../../organisms/Footer/Footer";
import CompanyInfo from "../../organisms/CompanyInfo/CompanyInfo";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./Company.scss";

const Company = () => {
  return (
    <>
      <Helmet>
        <title>Company Information</title>
      </Helmet>
      <Header currentPage={"会社概要"} />
      <div className="wrapper">
        <div className="breadcrumb">
          <span>
            <Link to={"/"}>ホーム</Link>
          </span>
          <span>&nbsp;&nbsp;&gt;&nbsp;&nbsp;</span>
          <span>会社概要</span>
        </div>
        <div className="container-company">
          <CompanyInfo />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Company;
