import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Top from "./pages/Top/Top";
import Company from "./pages/Company/Company";
import NewsList from "./pages/NewsLIst/NewsList";
import NewsDetail from "./pages/NewsDetail/NewsDetail";
import Recruit from "./pages/Recruit/Recruit";
import Query from "./pages/Query/Query";
import ScrollToTop from "./utils/ScrollToTop/ScrollToTop";

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Top />} />
        <Route path="/company" element={<Company />} />
        <Route path="/news" element={<NewsList />} />
        <Route path="/news/:id" element={<NewsDetail />} />
        <Route path="/recruit" element={<Recruit />} />
        <Route path="/query" element={<Query />}></Route>
      </Routes>
    </Router>
  );
};

export default App;
