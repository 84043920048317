import React from "react";
import "./CompanyDetail.scss";
import { Grid } from "@mui/material";

type CompanyDetailProps = {
  title: string;
  info: string;
};

const CompanyDetail: React.FC<CompanyDetailProps> = ({ title, info }) => {
  return (
    <Grid container className="container-detail">
      <Grid item xs={12} sm={3} className="title-detail">
        <p>{title}</p>
      </Grid>
      <Grid item xs={12} sm={9} className="info-detail">
        <p>{info}</p>
      </Grid>
    </Grid>
  );
};

export default CompanyDetail;
