import React from "react";
import { Link } from "react-router-dom";
import Header from "../../organisms/Header/Header";
import Footer from "../../organisms/Footer/Footer";
import ContentBar from "../../molecules/ContentBar/ContentBar";
import { Helmet } from "react-helmet";
import "./Query.scss";

const Query = () => {
  return (
    <>
      <Helmet>
        <title>Enquery</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Header currentPage={"お問い合わせ"} />
      <div className="wrapper">
        <div className="container-query">
          <div className="breadcrumb">
            <span>
              <Link to={"/"}>ホーム</Link>
            </span>
            <span>&nbsp;&nbsp;&gt;&nbsp;&nbsp;</span>
            <span>お問い合わせ</span>
          </div>
          <ContentBar title={"問い合わせ"} />
          {/* <span className="text-query">
            info@libra.co.jpまでお問い合わせください
          </span> */}
          <div className="contact-form">
            <form name="contact" method="POST">
              <input type="hidden" name="form-name" value="contact" />
              <div className="form-item">
                <label htmlFor="name">お名前</label>
                <input
                  className="form-field"
                  name="name"
                  type="text"
                  required
                />
              </div>
              <div className="form-item">
                <label htmlFor="company">会社名</label>
                <input
                  className="form-field"
                  name="company"
                  type="text"
                  required
                />
              </div>
              <div className="form-item">
                <label htmlFor="email">ご連絡先メールアドレス</label>
                <input
                  className="form-field"
                  name="email"
                  type="email"
                  required
                />
              </div>
              <div className="form-item">
                <label htmlFor="content">お問い合わせ内容</label>
                <textarea
                  className="form-field"
                  name="content"
                  id="contact-content"
                  rows={10}
                  required
                ></textarea>
              </div>
              <button className="form-button" type="submit">
                送信する
              </button>
            </form>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Query;
