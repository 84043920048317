import React from "react";
import "./News.scss";
import { NewsProps } from "../../asset/text/news";
import { Link } from "react-router-dom";

const News: React.FC<NewsProps> = ({ id, date, title, tag }) => {
  console.log(id);
  return (
    <div className="container-news">
      <div className="meta-news">
        <p>{date}</p>
        <div className="tag-news">
          <span>{tag}</span>
        </div>
      </div>
      <div>
        <h3>
          <Link to={`/news/${id}`}>{title}</Link>
        </h3>
      </div>
    </div>
  );
};

export default News;
